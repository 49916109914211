import React, { Suspense } from "react";
import "./App.css";
import banner2 from "./images/banner2.jpg";
import banner3 from "./images/banner3.jpg";
import banner from "./images/banner.jpg";
import loadable from "@loadable/component";

// Find out if user is using ios, to disable certain parallax elements

const NavigationBar = loadable(() => import("./components/Navbar"));
const Banner = loadable(() => import("./components/Banner"));
const About = loadable(() => import("./components/About"));
const Development = loadable(() => import("./components/Development"));
const BannerTwo = loadable(() => import("./components/Banner2"));
const ManagedServices = loadable(() => import("./components/ManagedServices"));
const UXDesign = loadable(() => import("./components/UXDesign"));
// const VDIntegration = loadable(() => import("./components/VDIntegration"));
const MobileApps = loadable(() => import("./components/MobileApps"));
const Clients = loadable(() => import("./components/Clients"));
const BannerThree = loadable(() => import("./components/Banner3"));
const PartnerIntegrations = loadable(() =>
  import("./components/PartnerIntegrations")
);
const Contact = loadable(() => import("./components/Contact"));

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  window.opSystem = "";
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    window.opSystem = "windows";
  } else if (/android/i.test(userAgent)) {
    window.opSystem = "android";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.opSystem = "ios";
  } else if (
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    window.opSystem = "ios";
  } else {
    window.opSystem = "unknown";
  }
  return window.opSystem;
}

// Add parallax effect for background images on ios and to the iphone content on scrolling

let scrolling = false;
let lastScrollTop = 0;

document.addEventListener("scroll", function (e) {
  const bannerElement = document.querySelector(".banner");
  const bannerIphone = document.querySelector(".banner-iphone");
  const managedServicesIphone = document.querySelector(
    ".managed-services-iphone"
  );
  const mobileAppsIphone = document.querySelector(".mobile-apps-iphone");
  let st = window.pageYOffset || document.documentElement.scrollTop;

  if (st > 736 && st < 3000) {
    bannerElement.style.backgroundImage = `url(${banner2})`;
  } else if (st > 3001) {
    bannerElement.style.backgroundImage = `url(${banner})`;
  } else if (st < 736) {
    bannerElement.style.backgroundImage = `url(${banner3})`;
  }

  if (st > lastScrollTop) {
    // downscroll code
    bannerIphone.style.animationPlayState = "running";
    managedServicesIphone.style.animationPlayState = "running";
    mobileAppsIphone.style.animationPlayState = "running";
    bannerIphone.style.animationDirection = "alternate";
    managedServicesIphone.style.animationDirection = "alternate";
    mobileAppsIphone.style.animationDirection = "alternate";
  } else {
    // upscroll code
    bannerIphone.style.animationPlayState = "running";
    managedServicesIphone.style.animationPlayState = "running";
    mobileAppsIphone.style.animationPlayState = "running";
    // bannerIphone.style.animationDirection = 'reverse'
    // managedServicesIphone.style.animationDirection = 'reverse'
  }

  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

  if (!scrolling) {
    setTimeout(() => {
      bannerIphone.style.animationPlayState = "paused";
      managedServicesIphone.style.animationPlayState = "paused";
      mobileAppsIphone.style.animationPlayState = "paused";
    }, 200);
    // window.requestAnimationFrame(function () {
    //   scrolling = false;
    // })
    // scrolling = true;
  }
});

export default function App() {
  getMobileOperatingSystem();
  return (
    <div className="App" id="home">
      <Suspense fallback={<div>Loading...</div>}>
        <NavigationBar />
        <Banner />
        <About />
        <Development />
        <BannerTwo />
        <ManagedServices />
        <UXDesign />
        {/* <VDIntegration /> */}
        <MobileApps />
        <Clients />
        <BannerThree />
        <PartnerIntegrations />
        <Contact />
      </Suspense>
    </div>
  );
}
